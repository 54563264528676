function getMessageFromResponse(res) {
  let message = "";
  if (
    res.data &&
    res.data.data &&
    res.data.data.error &&
    res.data.data.error.message
  ) {
    message = res.data.data.error.message;
  } else if (res.data && res.data.data && res.data.data.message) {
    message = res.data.data.message;
  } else if (
    res.data &&
    res.data.data &&
    res.data.data.error &&
    typeof res.data.data.error === "string"
  ) {
    message = res.data.data.error;
  } else if (res.error && typeof res.error === "string") {
    message = res.error;
  } else if (res.data && res.data.error && res.data.error.message) {
    message = res.data.error.message;
  } else if (res.data && res.data.note) {
    message = res.data.note;
  } else if (res.note) {
    message = res.note;
  } else if (res.data && res.data.msg) {
    message = res.data.msg;
  } else if (res.msg) {
    message = res.msg;
  } else if (res.data && res.data.message) {
    message = res.data.message;
  } else if (res.message) {
    message = res.message;
  } else if (res.data && res.data.notes) {
    message = res.data.notes.join("\n");
  } else if (res.data && res.data.notice) {
    message = res.data.notice;
  } else if (res.data && res.data.error && typeof res.data.error === "string") {
    message = res.data.error;
  } else if (
    res.data &&
    res.data.error_message &&
    typeof res.data.error_message === "string"
  ) {
    message = res.data.error_message;
  } else if (typeof res === "string" && res) {
    message = res;
  } else if (res.rate_limit && res.rate_limit_message) {
    message = res.rate_limit_message;
  } else if (res.data && res.data.rate_limit && res.data.rate_limit_message) {
    message = res.data.rate_limit_message;
  } else if (res.error && res.error.message) {
    message = res.error.message;
  }

  if (res.data && res.data.errors) {
    try {
      message = `${Object.keys(res.data.errors).map((key) =>
        res.data.errors[key].join("\n")
      )}`;
    } catch (e) {
      try {
        let parts = [];
        res.data.errors.forEach((error) => {
          const message = error.error.errors[0].message;
          const key = error.error.key;
          if (!message.toLowerCase().includes(key.toLowerCase())) {
            parts.push(
              `${error.error.key.replace(/^\w/, (chr) =>
                chr.toUpperCase()
              )}: ${message}`
            );
          } else {
            parts.push(message);
          }
        });
        message = parts.join("\n");
      } catch (e) {
        message = message || "Something went wrong";
      }
    }
  }

  return message;
}

export default getMessageFromResponse;
