/* eslint-disable import/first */
import React from "react";
import ReactDOM from "react-dom/client";
import $ from "jquery";
import { Dropdown } from "bootstrap";

window.jQuery = window.$ = $;
window.Dropdown = Dropdown;

require("bootstrap-select");

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
