import Navbar from "components/Navbar";

function NoAccessPage(props) {
  return (
    <div>
      <Navbar />
      <div className="container-lg bd-gutter mt-3">
        <h1 className="fw-bold wizard-title text-center">
          Seems like you don't have required permissions to perform this actions
        </h1>
        <p className="wizard-subtitle text-center">
          Please contact your organization admin or owner to install apps or to
          provide you correct rights.
        </p>
        <div className="text-center">
          <button onClick={props.logout} className="btn btn-outline-secondary">
            Logout
          </button>
        </div>
      </div>
    </div>
  );
}

export default NoAccessPage;
