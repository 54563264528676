import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Component } from "react";

import fetchApi from "common/fetchApi";
import ToastsContext from "contexts/ToastsContext";

import Toasts from "components/Toasts";
import WizardPage from "components/WizardPage";
import AuthCallbackPage from "components/AuthCallbackPage";
import NotFoundPage from "./components/NotFoundPage";

const router = createBrowserRouter([
  {
    path: "/:app",
    element: <WizardPage />,
  },
  {
    path: "/auth/callback",
    element: <AuthCallbackPage />,
  },
  {
    path: "*",
    element: <NotFoundPage isWrongPage />,
  },
]);

class App extends Component {
  showToast = (text) => {
    const { toasts } = this.state;
    const isToastExist = toasts.filter((toast) => toast === text).length > 0;
    if (!isToastExist) {
      this.setState({ toasts: [...toasts, text] });
      setTimeout(() => {
        this.setState({ toasts: toasts.filter((toast) => toast !== text) });
      }, 3000);
    }
  };

  state = {
    toasts: [],
    showToast: this.showToast,
  };

  componentDidMount() {
    fetchApi.showToast = this.showToast;
  }

  render() {
    return (
      <ToastsContext.Provider value={this.state}>
        <RouterProvider router={router} />
        <Toasts />
      </ToastsContext.Provider>
    );
  }
}

export default App;
