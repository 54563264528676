import { Component, createRef } from "react";
import { find } from "lodash";

import storage from "common/storage";
import fetchApi from "common/fetchApi";
import withToasts from "hoc/withToasts";

import Card from "components/Card";
import CardText from "components/CardText";

const ENGLISH_LANGUAGE_ID = "en";

class ProjectStep extends Component {
  projectNameInputRef = createRef();

  state = {
    name: "",
    identifier: "",
    isFetching: false,
    sourceLanguageId: ENGLISH_LANGUAGE_ID,
    targetLanguageIds: [],
    projects: [],
    showProjectsSelect: false,
    projectId: null,
  };

  componentDidMount() {
    this.initSelects();
    if (this.props.token) {
      this.processSavedData();
      this.fetchProjects();
    }
    window.addEventListener("focus", this.focusHandler);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.languages.length && this.props.languages.length) {
      this.refreshSelects();
    }

    if (prevProps.isDisabled !== this.props.isDisabled) {
      this.rerenderSelects();
      this.setState({
        showProjectsSelect: false,
        projects: [],
        projectId: null,
      });
    }

    if (prevState.showProjectsSelect !== this.state.showProjectsSelect) {
      this.initSelects();
    }

    if (!prevProps.token && this.props.token) {
      this.processSavedData();
    }

    if (prevProps.token && !this.props.token) {
      this.clearForm();
    }

    if (!prevProps.user && this.props.user) {
      this.fetchProjects();
    }

    if (!prevProps.isActive && this.props.isActive) {
      this.projectNameInputRef.current.focus();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("focus", this.focusHandler);
  }

  fetchProjects = () => {
    const { token, domain, user } = this.props;

    if (!user || !user.id) {
      return;
    }

    console.log('domain', domain);

    fetchApi(`api/projects`, token, {
      userId: user.id,
      ...(domain ? { domain } : {}),
    }, 'GET', true).then((projects) => {
      this.setState({
        projects: projects.data,
        projectId: projects.data.length ? projects.data[0].id : null,
      });
    });
  }

  focusHandler = () => {
    const {
      projectId,
      token,
      domain,
      getWorkflowTemplates,
      workflowId,
      setWorkflowId,
    } = this.props;

    if (projectId && token) {
      this.fetchProject(projectId);
    }

    if (domain && token) {
      getWorkflowTemplates().then((templates) => {
        const isSelectedTemplateExists = find(
          templates,
          (template) => template.id === workflowId
        );
        if (!isSelectedTemplateExists && templates.length) {
          setWorkflowId(templates[0].id);
        }
      });
    }
  };

  processSavedData() {
    const projectId = storage.getProjectId();
    if (!projectId) {
      return;
    }

    const { onProjectSelected } = this.props;

    this.fetchProject(projectId, true).then(
      (project) => project && onProjectSelected(project.id, project.identifier)
    );
  }

  fetchProject = (projectId, forceSelect = false) => {
    const { token } = this.props;

    return fetchApi(`projects/${projectId}`, token).then((project) => {
      const { token, projectId } = this.props;
      if (project && token && (projectId || forceSelect)) {
        this.setState({
          name: project.name,
          identifier: project.identifier,
          sourceLanguageId: project.sourceLanguageId,
          targetLanguageIds: project.targetLanguageIds,
        });
      }

      return project;
    });
  };

  setName = (name) => this.setState({ name });

  setSourceLanguageId = (sourceLanguageId) =>
    this.setState({ sourceLanguageId });

  setTargetLanguageIds = (targetLanguageIds) =>
    this.setState({ targetLanguageIds });

  clearForm = () => {
    this.setState(
      {
        name: "",
        sourceLanguageId: ENGLISH_LANGUAGE_ID,
        targetLanguageIds: [],
        identifier: "",
      },
      () => {
        window
          .$("#project-source-language")
          .selectpicker("val", ENGLISH_LANGUAGE_ID);
        window.$("#project-target-languages").selectpicker("val", []);
        this.rerenderSelects();
      }
    );
  };

  initSelects = () => {
    window.$(".language-select").selectpicker();
    window
      .$("#project-target-languages")
      .on("changed.bs.select", (e, clickedIndex, isSelected, previousValue) => {
        const { languages } = this.props;
        if (clickedIndex === null) {
          return;
        }
        const { targetLanguageIds } = this.state;
        const languageId = languages[clickedIndex].id;

        if (targetLanguageIds.includes(languageId)) {
          this.setTargetLanguageIds(
            targetLanguageIds.filter((id) => id !== languageId)
          );
        } else {
          this.setTargetLanguageIds([...targetLanguageIds, languageId]);
        }
      });
  };

  rerenderSelects = () => {
    window.$(".language-select").selectpicker("render");
  };

  refreshSelects = () => {
    window.$(".language-select").selectpicker("refresh");
  };

  render() {
    const {
      name,
      sourceLanguageId,
      targetLanguageIds,
      identifier,
      isFetching,
      projects,
      projectId: localProjectId,
      showProjectsSelect,
    } = this.state;
    const {
      user,
      templatesFetched,
      templates,
      languages,
      domain,
      token,
      onProjectSelected,
      isActive,
      isDisabled,
      projectId,
      showToast,
      workflowId,
      setWorkflowId,
      appStoreData,
    } = this.props;

    const sourceLanguage =
      find(languages, ({ id }) => id === sourceLanguageId) || {};

    return (
      <Card
        className="mt-4"
        isActive={isActive}
        isDisabled={isDisabled}
        title="Crowdin project"
      >
        <CardText>
          For easier management of your {appStoreData.name} localization, it is
          recommended to have a separate project for it. This wizard will help
          you quickly create a Crowdin project.
        </CardText>
        {!showProjectsSelect && (
        <form
          className={projectId ? "d-none" : ""}
          onSubmit={(e) => {
            e.preventDefault();

            if (name.length < 3) {
              showToast("Name is required and should be 3+ chars length");
              return;
            }

            this.setState({ isFetching: true });

            fetchApi(
              "projects",
              token,
              {
                name,
                ...(domain ? { templateId: workflowId } : {}),
                sourceLanguageId,
                targetLanguageIds,
              },
              "POST"
            ).then((project) => {
              this.setState({ isFetching: false });

              if (!project) {
                return;
              }

              if (window.dataLayer) {
                window.dataLayer.push({
                  category: 'Create',
                  event: domain ? 'create-project' : 'create_project',
                  userId: user.id,
                  domain,
                });

                if (!domain) {
                  fetchApi('projects', token, {
                    hasManagerAccess: 1,
                  }).then(projects => {
                    if (projects.length === 1) {
                      window.dataLayer.push({
                        userId: user.id,
                        event: "start_trial",
                      });
                    }
                  });
                }
              }

              this.setState({ identifier: project.identifier });
              storage.setProjectId(project.id);
              onProjectSelected(project.id, project.identifier);
            });
          }}
        >
          <div className="mb-3">
            <label htmlFor="project-name" className="form-label">
              Project name
            </label>
            <input
              type="text"
              disabled={isDisabled}
              className="form-control"
              id="project-name"
              placeholder="Usually the name of your website or app"
              value={name}
              onChange={(e) => this.setName(e.target.value)}
              ref={this.projectNameInputRef}
            />
          </div>
          {domain && (!templatesFetched || templates.length > 0) && (
            <div className="mb-3">
              <label htmlFor="project-workflow" className="form-label">
                Workflow template
              </label>
              <select
                id="project-workflow"
                className="form-control"
                value={workflowId || ""}
                // className='language-select'
                // data-live-search="true"
                onChange={(e) => setWorkflowId(+e.target.value)}
                disabled={isDisabled}
              >
                {templates.map((template) => (
                  <option key={template.id} value={template.id}>
                    {template.title}
                  </option>
                ))}
              </select>
              <a
                className="help-text"
                href={`https://${domain}.${process.env.REACT_APP_API_BASE_URL}/u/workflows/${workflowId}/edit`}
                target="_blank"
                rel="noreferrer"
              >
                Edit template
              </a>
            </div>
          )}
          {domain && templatesFetched && templates.length === 0 && (
            <div className="mb-3">
              <div className="alert alert-warning">
                You can't create project without workflow template. Please{" "}
                <a
                  rel="noreferrer"
                  href={`https://${domain}.${process.env.REACT_APP_API_BASE_URL}/u/workflows/create`}
                  target="_blank"
                >
                  create new workflow template
                </a>
              </div>
            </div>
          )}
          <div className="row mb-3">
            <div className="col-6">
              <label htmlFor="project-source-language" className="form-label">
                Source language
              </label>
              <select
                id="project-source-language"
                value={sourceLanguageId}
                className="language-select"
                onChange={(e) => this.setSourceLanguageId(e.target.value)}
                data-live-search="true"
              >
                {languages.map((language) => (
                  <option key={language.id} value={language.id}>
                    {language.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-6">
              <label className="form-label" htmlFor="project-target-languages">
                Target languages
              </label>
              <select
                multiple
                id="project-target-languages"
                onChange={() => {}}
                value={targetLanguageIds}
                className="language-select"
                data-live-search="true"
              >
                {languages.map((language) => (
                  <option key={language.id} value={language.id}>
                    {language.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-outline-secondary me-4"
            disabled={isDisabled || isFetching || (domain && !templates.length)}
          >
            {isFetching && (
              <span
                className="spinner-border spinner-border-sm me-1"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            Create
          </button>
          {projects.length > 0 && (
            <span
              className="link-primary"
              onClick={() => this.setState({ showProjectsSelect: true })}
            >
              Select existing project
            </span>
          )}
        </form>
        )}
        {showProjectsSelect && !projectId &&
          <form
            onSubmit={e => {
              e.preventDefault();
              this.setState({ isFetching: true });
              this.fetchProject(localProjectId, true).then(
                (project) => {
                  project && onProjectSelected(project.id, project.identifier);
                  this.setState({ isFetching: false, showProjectsSelect: false });
                }
              );
            }}
          >
            <div className="mb-3">
              <label htmlFor="project-select" className="form-label">
                Project
              </label>
              <select
                id="project-select"
                className="form-control"
                value={localProjectId || ""}
                onChange={(e) => this.setState({ projectId: +e.target.value })}
                disabled={isDisabled}
              >
                {projects.map(project => (
                  <option key={project.id} value={project.id}>
                    {project.name}
                  </option>
                ))}
              </select>
            </div>
            <button
              type="submit"
              className="btn btn-outline-secondary me-4"
              disabled={isDisabled || isFetching || (domain && !templates.length) || !localProjectId}
            >
              {isFetching && (
                <span
                  className="spinner-border spinner-border-sm me-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Proceed
            </button>
            <span
              className="link-primary"
              onClick={() => this.setState({ showProjectsSelect: false })}
            >
              Create project
            </span>
          </form>
        }
        {projectId && (
          <>
            <div className="wizard-entity-wrapper mb-3 mt-4 justify-content-between">
              <div>
                <span className="fw-500">{name}</span>
                <div className="text-muted text-small d-flex align-items-center">
                  <span className="me-1">
                    {sourceLanguage.name} ({sourceLanguage.id})
                  </span>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.99992 3.33331L8.82492 4.50831L13.4749 9.16665H3.33325V10.8333H13.4749L8.82492 15.4916L9.99992 16.6666L16.6666 9.99998L9.99992 3.33331Z"
                      fill="#263238"
                      fillOpacity="0.3"
                    />
                  </svg>
                  <span className="ms-1">
                    {targetLanguageIds.length}{" "}
                    {targetLanguageIds.length === 1 ? "language" : "languages"}
                  </span>
                </div>
              </div>
              <div>
                <a
                  href={
                    domain
                      ? `https://${domain}.${process.env.REACT_APP_API_BASE_URL}/u/projects/${projectId}/settings`
                      : `https://${process.env.REACT_APP_API_BASE_URL}/project/${identifier}/settings`
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-outline-secondary"
                >
                  Settings
                </a>
              </div>
            </div>
            <span
              className="link-primary"
              onClick={(e) => {
                e.preventDefault();
                storage.removeProjectId();
                onProjectSelected(null);
                this.clearForm();
              }}
            >
              Create another project
            </span>
          </>
        )}
      </Card>
    );
  }
}

export default withToasts(ProjectStep);
