import { Component } from "react";

import CrowdinLoginButtons from "./CrowdinLoginButtons";

import fetchApi from "common/fetchApi";
import { openWindow, observeWindow } from "./helpers";

export default class CrowdinLoginComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCompleted: false,
    };
  }

  componentDidMount() {
    this.initializeProcess();
  }

  initializeProcess = () => {
    if (window.opener && window.location.search.includes('code=')) {
      const [match, code] =
        window.location.search.match(/.*code=([^&|\n|\t\s]+)/) || [];
      window.opener.postMessage(
        {
          type: "code",
          data: code,
          match,
        },
        window.origin
      );
    } else {
      const { authCallback } = this.props;
      window.onmessage = ({ data: { type, data } }) => {
        if (type === "code") {
          this.sendTokenRequest(data).then((data) => {
            const { popup } = this.state;
            this.setState(
              {
                isCompleted: true,
              },
              () => {
                authCallback && authCallback(undefined, data);
                popup && popup.close();
              }
            );
          });
        }
      };
    }
  };

  buildLoginCodeRequestURL = () => {
    return `https://accounts.${process.env.REACT_APP_API_BASE_URL}/login?intended=${this.buildIntended()}`;
  };

  buildSignUpCodeRequestURL = () => {
    return `https://accounts.${process.env.REACT_APP_API_BASE_URL}/register?intended=${this.buildIntended()}`;
  };

  buildIntended = () => {
    const { clientId, redirectUri, scope } = this.props;
    const uri = encodeURIComponent(redirectUri || window.location.href);

    return encodeURIComponent(`/oauth/authorize?client_id=${clientId}&redirect_uri=${uri}&response_type=code&scope=${scope}`);
  }

  sendTokenRequest = (code) => {
    const {
      clientId: client_id,
      clientSecret: client_secret,
      redirectUri,
    } = this.props;
    const redirect_uri = redirectUri || window.location.href;
    return fetchApi(
      `api/token`,
      null,
      {
        client_id,
        client_secret,
        redirect_uri,
        grant_type: "authorization_code",
        code,
      },
      "POST",
      true
    );
  };

  handleLoginClick = () => {
    const popup = openWindow({
      url: this.buildLoginCodeRequestURL(),
      name: "Log in with Crowdin",
    });

    if (popup) {
      observeWindow({ popup, onClose: this.handleClosingPopup });
      this.setState({
        popup,
      });
    }
  };

  handleSignUpClick = () => {
    const popup = openWindow({
      url: this.buildSignUpCodeRequestURL(),
      name: "Sign up for Crowdin",
    });

    if (popup) {
      observeWindow({ popup, onClose: this.handleClosingPopup });
      this.setState({
        popup,
      });
    }
  };

  handleClosingPopup = () => {
    const { authCallback } = this.props;
    const { isCompleted } = this.state;
    if (!isCompleted) {
      authCallback && authCallback("User closed OAuth popup");
    }
  };

  render() {
    return (
      <>
        <CrowdinLoginButtons onLoginClick={this.handleLoginClick} onSignUpClick={this.handleSignUpClick} />
      </>
    );
  }
}
