import React, { Component } from "react";

import fetchApi from "common/fetchApi";
import withToasts from "hoc/withToasts";

import Card from "components/Card";
import CardText from "components/CardText";
import AppPlaceholder from "components/AppPlaceholder";

class IframeModule extends Component {
  wrapperRef = React.createRef();

  state = {
    getJwtTokenStatus: {
      isFetching: false,
      isFetched: false,
      data: {},
    },
    isApiInitialized: false,
    isLoaded: false,
    styles: {
      height: "100%",
      width: "calc(100% + 48px)",
      border: 0,
      margin: "-16px -24px",
      display: "block",
    },
  };

  iframeIds = {};

  iframeUrl = "";

  getJwtToken = (appModule, context = {}, params) => {
    return fetchApi(
      `api/app-token`,
      this.props.token,
      {
        ...params,
        type: appModule.type,
        context,
        moduleKey: appModule.key,
        appModuleIdentifier: appModule.identifier,
      },
      "POST",
      true
    ).then((r) => {
      if (!r || !r.data || !r.data.token) {
        this.props.showToast(
          "Integration doesn\t respond. Please try again later or contact Crowdin support."
        );
      }

      this.setState({
        getJwtTokenStatus: {
          data: r.data || {},
          isFetching: false,
          isFetched: true,
        },
      });
      return r;
    });
  };

  componentDidMount() {
    const { appModule, projectId } = this.props;

    if (!appModule || !appModule.identifier || !projectId) {
      return;
    }

    this.init();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { projectId, appModule } = this.props;

    if (
      prevProps.projectId !== projectId ||
      prevProps.appModule !== appModule
    ) {
      if (projectId && appModule && appModule.identifier) {
        this.init();
      } else {
        this.setState({
          isLoaded: false,
          isApiInitialized: false,
          getJwtTokenStatus: {
            isFetching: false,
            isFetched: false,
            data: {},
          },
        });
      }
    }

    if (!prevProps.isActive && this.props.isActive) {
      this.wrapperRef.current.scrollIntoView();
    }
  }

  init = () => {
    const { appContext, appModule, requestParams } = this.props;

    this.getJwtToken(appModule, appContext, requestParams || {});

    this.addMessageListener(appModule, appContext);
    this.initDefaultIframeApi();

    this.iframeRef = React.createRef();

    this.setState({
      isApiInitialized: true,
    });
  };

  getContext() {
    return {
      project_id: this.props.projectId,
    };
  }

  initCallback() {
    const { appModule } = this.props;
    const frameId = this.getId(appModule);

    const initCallback = (extensionId, iframe) => {
      this.log("Installed event received", extensionId);

      setTimeout(() => {
        this.setState({
          isLoaded: true,
        });
      }, 1000);

      this.iframeIds[frameId] = iframe.source;
    };

    return function callback(extensionId) {
      initCallback(extensionId, this);
    };
  }

  getIframeUrl(appModule, params) {
    const delimiter =
      appModule.iframe.location.url.indexOf("?") === -1 ? "?" : "&";
    const iframeUrl = `${
      appModule.iframe.location.url
    }${delimiter}${new URLSearchParams(params).toString()}`;

    return iframeUrl;
  }

  log(message, ...rest) {
    if (process.env.NODE_ENV === "development") {
      window.console.log(`[DEBUG] ${message}`, ...rest);
    }
  }

  getId(appModule) {
    return this.generateId(appModule.identifier, appModule.key);
  }

  generateId(appIdentifier, appModuleKey) {
    return `${appIdentifier}¦${appModuleKey}`;
  }

  parseJwtClaims(jwt) {
    if (jwt === null || jwt === "") {
      return;
    }

    let firstPeriodIndex = jwt.indexOf(".");
    let secondPeriodIndex = jwt.indexOf(".", firstPeriodIndex + 1);

    if (firstPeriodIndex < 0 || secondPeriodIndex <= firstPeriodIndex) {
      return;
    }

    let encodedClaims = jwt.substring(firstPeriodIndex + 1, secondPeriodIndex);

    if (encodedClaims === null || encodedClaims === "") {
      return;
    }

    return JSON.parse(atob(encodedClaims));
  }

  isJwtExpired(jwtString) {
    let claims = this.parseJwtClaims(jwtString);
    let expires = 0;
    let now = Math.floor(Date.now() / 1000); // UTC timestamp now

    const skew = 60;

    if (claims && claims.exp) {
      expires = claims.exp;
    }

    if (expires - skew < now) {
      return true;
    }

    return false;
  }

  postMessage(appModule, messageData, data) {
    const id = this.getId(appModule);

    this.iframeIds[id] &&
      this.iframeIds[id].postMessage(
        JSON.stringify({
          client_id: messageData.client_id,
          command: messageData.command,
          uid: messageData.uid,
          data,
        }),
        appModule.iframe.data.origin
      );
  }

  messageListener(appModule, appContext) {
    const { requestParams } = this.props;

    return (event) => {
      this.log("Received event", event);

      if (typeof event.data !== "string") {
        this.log("Lib event received", event);

        return;
      }

      if (!event.isTrusted || event.origin !== appModule.iframe.data.origin) {
        this.log("Exit", appModule.iframe.data.origin);

        return;
      }

      const messageData = JSON.parse(event.data);

      this.log("Received data", messageData);

      if (messageData.command === "token") {
        this.getJwtToken(appModule, appContext, requestParams || {}).then(
          (data) => {
            if (data && data.token) {
              this.postMessage(appModule, messageData, {
                tokenJwt: data.token,
              });
            }
          }
        );
      }
    };
  }

  getHeaderHeight = () => 0;

  initDefaultIframeApi() {
    window.host &&
      window.host.defineGlobals({
        getContext: (res) => {
          if (!res._context.extension || !res._context.extension.key) {
            return;
          }

          const { appContext } = this.props;

          res(appContext);
        },
        getViewportSize: (res) => {
          if (!res._context.extension || !res._context.extension.key) {
            return;
          }

          const appIframe = document.querySelector(
            `iframe#${res._context.extension.key}`
          );
          const rect = appIframe.getBoundingClientRect();

          const iframeHeight = appIframe.offsetHeight;
          const winHeight = window.innerHeight;
          const headerHeight = this.getHeaderHeight();
          let visibleHeight = Math.max(
            0,
            rect.top > 0
              ? Math.min(iframeHeight, winHeight - rect.top)
              : Math.min(rect.bottom, winHeight)
          );

          if (rect.top < 0) {
            visibleHeight -= headerHeight;
          } else if (rect.top < headerHeight) {
            visibleHeight = visibleHeight - (headerHeight - rect.top);
          }

          res({
            width: appIframe.offsetWidth,
            height: visibleHeight,
          });
        },
        getScrollPosition: (res) => {
          if (!res._context.extension || !res._context.extension.key) {
            return;
          }

          const appIframe = document.querySelector(
            `iframe#${res._context.extension.key}`
          );
          const rect = appIframe.getBoundingClientRect();
          const headerHeight = this.getHeaderHeight();

          if (rect.top > headerHeight) {
            res(0);
          } else if (rect.top > 0) {
            res(headerHeight - rect.top);
          } else {
            res(Math.abs(rect.top) + headerHeight);
          }
        },
        getJwtToken: (res) => {
          if (!res._context.extension || !res._context.extension.key) {
            return;
          }

          const { appContext, appModule, requestParams } = this.props;

          if (appModule.authenticationType === "none") {
            res(null);
          } else if (
            !this.state.getJwtTokenStatus.data.token ||
            this.isJwtExpired(this.state.getJwtTokenStatus.data.token)
          ) {
            this.getJwtToken(appModule, appContext, requestParams || {}).then(
              (data) => res(data.token || null)
            );
          } else {
            res(this.state.getJwtTokenStatus.data.token);
          }
        },
      });

    window.host.defineModule("env", {
      resize: (width, height, res) => {
        if (!res._context.extension || !res._context.extension.key) {
          return;
        }

        window.host.dispatch(
          "get-frame-size",
          {
            key: res._context.extension.key,
          },
          {},
          (width, height) => {
            const { styles } = this.state;
            const { onResize } = this.props;

            styles.height = `${height}px`;

            this.setState({
              styles,
            });

            onResize && onResize();
          }
        );
      },
    });
  }

  addMessageListener(appModule, appContext) {
    this.log("Init app listener", appModule);

    window.removeEventListener("message", window.appLastMessageListener);

    window.appLastMessageListener = this.messageListener(appModule, appContext);
    window.addEventListener("message", window.appLastMessageListener);
  }

  renderPlaceholder = () => {
    if (!this.props.isDisabled) {
      return (
        <div className="wizard-app-loader">
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      );
    }

    return (
      <div className='d-flex justify-content-center pt-4'>
        <AppPlaceholder />
      </div>
    );
  };

  renderContent() {
    const {
      isApiInitialized,
      isLoaded,
      styles: stateStyles,
      getJwtTokenStatus,
    } = this.state;
    const { appModule, style: propsStyle } = this.props;

    if (
      !isApiInitialized ||
      !getJwtTokenStatus.isFetched ||
      !appModule ||
      !appModule.iframe
    ) {
      return this.renderPlaceholder();
    }

    let params = {
      ...appModule.iframe.location.params,
      jwtToken: getJwtTokenStatus.data.token,
      tokenJwt: getJwtTokenStatus.data.token,
    };

    let windowData = {
      addon_key: appModule.identifier,
      key: this.getId(appModule),
      url: this.getIframeUrl(appModule, params),
      options: {
        autoresize: true,
        ...appModule.iframe.data.app,
        context: this.getContext(),
        hostFrameOffset: 1,
        tokenJwt: getJwtTokenStatus.data.token,
        jwtToken: getJwtTokenStatus.data.token,
      },
    };

    const iframeParams = window.host.create(windowData, this.initCallback());

    return (
      <>
        {!isLoaded && this.renderPlaceholder()}
        <iframe
          id={this.getId(appModule)}
          src={iframeParams.src}
          name={iframeParams.name}
          style={{
            visibility: isLoaded ? "visible" : "hidden",
            ...stateStyles,
            ...propsStyle,
          }}
          allowFullScreen
          title="frame"
          ref={this.iframeRef}
        />
      </>
    );
  }

  render() {
    const { isActive, isDisabled, appStoreData } = this.props;

    return (
      <div ref={this.wrapperRef}>
        <Card
          className="mt-4"
          isActive={isActive}
          isDisabled={isDisabled}
          title="Setup integration"
        >
          <CardText>
            Connect to {appStoreData.name} and synchronize something from {appStoreData.name} to
            Crowdin in order to proceed to the next step.
          </CardText>
          <div style={{ minHeight: "250px" }}>{this.renderContent()}</div>
        </Card>
      </div>
    );
  }
}

export default withToasts(IframeModule);
