import parseJwt from "./parseJwt";
import getMessageFromResponse from "./getMessageFromResponse";

function fetchApi(
  url,
  token,
  payload = {},
  method = "GET",
  crossDomain = false
) {
  const { domain = "" } = token ? parseJwt(token) : {};

  if (crossDomain && domain) {
    payload.domain = domain;
  }

  const getParams =
    method === "GET" && Object.keys(payload).length
      ? `?${Object.keys(payload)
          .reduce((acc, curr) => {
            acc.push(`${curr}=${payload[curr]}`);
            return acc;
          }, [])
          .join("&")}`
      : "";

  let requestUrl = "";
  if (crossDomain) {
    requestUrl = `${
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_BASE_URL.replace("3001", "3002")
        : ""
    }/${url}${getParams}`;
  } else {
    const apiUrl = `${domain || ""}${domain ? "." : ""}api.${
      process.env.REACT_APP_API_BASE_URL
    }`;
    requestUrl = `https://${apiUrl}/api/v2/${url}${getParams}`;
  }

  return fetch(requestUrl, {
    headers: {
      "Content-Type": "application/json",
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
    method,
    body: method === "POST" ? JSON.stringify(payload) : null,
  })
    .then((r) => r.json())
    .then((r) => {
      const message = getMessageFromResponse({ data: r });
      const ignore = ["Unauthorized", "Forbidden"];
      if (message && !ignore.includes(message)) {
        fetchApi.showToast(message);
      }
      return crossDomain ? r : r.data;
    });
}

export default fetchApi;
