import { Component, createRef } from "react";

import Card from "components/Card";
import CardText from "components/CardText";

class TranslateStep extends Component {
  wrapperRef = createRef();

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.isActive && this.props.isActive) {
      this.wrapperRef.current.scrollIntoView();
    }
  }

  render() {
    const { domain, isActive, isDisabled, projectIdentifier, projectId } =
      this.props;

    return (
      <div ref={this.wrapperRef}>
        <Card
          title="Translate"
          isActive={isActive}
          className="mb-4 mt-4"
          isDisabled={isDisabled}
        >
          <CardText>
            Congratulations! You are now ready to purchase professional
            translations for your content. Alternatively, you can visit your
            Crowdin project to invite translators
          </CardText>
          <div className="wizard-entity-wrapper mt-4">
            <button
              onClick={() => {
                let href = `https://${process.env.REACT_APP_API_BASE_URL}/project/${projectIdentifier}/tasks/wizard#crowdin_language_service`;
                if (domain) {
                  href = `https://${domain}.${process.env.REACT_APP_API_BASE_URL}/u/projects/${projectId}/tasks/create`;
                }
                window.location.href = href;
              }}
              disabled={isDisabled}
              className="btn btn-primary btn--icon-left me-4"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="me-2"
              >
                <g clipPath="url(#clip0_2457_39032)">
                  <path
                    d="M5.8335 15C4.91683 15 4.17516 15.75 4.17516 16.6666C4.17516 17.5833 4.91683 18.3333 5.8335 18.3333C6.75016 18.3333 7.50016 17.5833 7.50016 16.6666C7.50016 15.75 6.75016 15 5.8335 15ZM0.833496 1.66663V3.33329H2.50016L5.50016 9.65829L4.37516 11.7C4.24183 11.9333 4.16683 12.2083 4.16683 12.5C4.16683 13.4166 4.91683 14.1666 5.8335 14.1666H15.8335V12.5H6.1835C6.06683 12.5 5.97516 12.4083 5.97516 12.2916L6.00016 12.1916L6.75016 10.8333H12.9585C13.5835 10.8333 14.1335 10.4916 14.4168 9.97496L17.4002 4.56663C17.4668 4.44996 17.5002 4.30829 17.5002 4.16663C17.5002 3.70829 17.1252 3.33329 16.6668 3.33329H4.34183L3.5585 1.66663H0.833496ZM14.1668 15C13.2502 15 12.5085 15.75 12.5085 16.6666C12.5085 17.5833 13.2502 18.3333 14.1668 18.3333C15.0835 18.3333 15.8335 17.5833 15.8335 16.6666C15.8335 15.75 15.0835 15 14.1668 15Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2457_39032">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Buy translations
            </button>
            <a
              rel="noreferrer"
              target="_blank"
              onClick={(e) => {
                if (isDisabled) {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              href={
                projectId
                  ? domain
                    ? `https://${domain}.${process.env.REACT_APP_API_BASE_URL}/u/projects/${projectId}`
                    : `https://${process.env.REACT_APP_API_BASE_URL}/project/${projectIdentifier}`
                  : "https://crowdin.com"
              }
            >
              Go to project
            </a>
          </div>
        </Card>
      </div>
    );
  }
}

export default TranslateStep;
