import storage from 'common/storage';

import Card from 'components/Card';
import CardText from 'components/CardText';
import LoginButton from 'components/LoginButton';
import { openWindow } from 'components/LoginButton/helpers';

function AuthStep(props) {
  const { onError, onTokenReceived, token, user, isActive, appStoreData } = props;

  let content = null;
  const cardText = (
    <CardText>
      To begin translating {appStoreData.name}, you will need either a Crowdin.com or
      Crowdin Enterprise account. Crowdin Enterprise is ideal for organizations,
      while Crowdin.com offers free subscription plans and is suitable for
      simple translation projects. Click the button below to register with
      Crowdin or log in with your existing account.
    </CardText>
  );

  const logoutRequestURL = `https://accounts.${process.env.REACT_APP_API_BASE_URL}/logout`;

  if (token) {
    content = (
      <>
        {cardText}
        <div className="wizard-entity-wrapper">
          <span className="wizard-user-btn">
            {user ? (
              <img alt="Avatar" src={user.avatarUrl} />
            ) : (
              <div className="wizard-avatar-loader" />
            )}
            {user ? (
              <span>{user.username}</span>
            ) : (
              <div className="wizard-text-loader" />
            )}
          </span>
          <span
            className="link-primary ms-4"
            onClick={(e) => {
              e.preventDefault();

              onTokenReceived(null);

              const popup = openWindow({
                url: logoutRequestURL,
                name: "Log out",
              });

              if (popup) {
                popup.close();
              }
            }}
          >
            Change account
          </span>
        </div>
      </>
    );
  } else {
    content = (
      <>
        {cardText}
        <LoginButton
          authCallback={(error, { access_token }) => {
            if (error) {
              onError(error);
            }

            if (!token && access_token) {
              storage.setToken(access_token);
              onTokenReceived(access_token);
            }
          }}
        />
      </>
    );
  }

  return (
    <Card title="Your Crowdin account" isActive={isActive}>
      {content}
    </Card>
  );
}

export default AuthStep;
