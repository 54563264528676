import LoginButton from "components/LoginButton";

// LoginButton should be present here to communicate with parent window

function AuthCallbackPage() {
  return (
    <>
      <div className="d-flex flex-column align-items-center mt-4">
        <div className="spinner-border text-success mb-2" role="status"></div>
        <span>Redirecting...</span>
      </div>
      <div style={{ display: "none" }}>
        <LoginButton />
      </div>
    </>
  );
}

export default AuthCallbackPage;
