import Navbar from "components/Navbar";

function NotFoundPage(props) {
  const { isWrongPage } = props;

  return (
    <div>
      <Navbar />
      <div className="container-lg bd-gutter mt-3">
        <h1 className="fw-bold wizard-title text-center">
          {isWrongPage ? "Page" : "App"} not found
        </h1>
        <p className="wizard-subtitle text-center">
          Please visit <a href="https://store.crowdin.com">Crowdin store</a> or{" "}
          <a href="https://crowdin.com/contacts">contact our Support team</a>.
        </p>
      </div>
    </div>
  );
}

export default NotFoundPage;
