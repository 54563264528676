function LeftColumn(props) {
  const { stepperActiveStepIndex } = props;

  const doneMark = (
    <div className="wizard-stepper__mark">
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.5 1.75C5.67 1.75 1.75 5.67 1.75 10.5C1.75 15.33 5.67 19.25 10.5 19.25C15.33 19.25 19.25 15.33 19.25 10.5C19.25 5.67 15.33 1.75 10.5 1.75ZM8.75 14.875L4.375 10.5L5.60875 9.26625L8.75 12.3988L15.3913 5.7575L16.625 7L8.75 14.875Z"
          fill="#43A047"
        />
      </svg>
    </div>
  );

  const activeMark = (
    <div className="wizard-stepper__mark">
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.5 1.75C5.67 1.75 1.75 5.67 1.75 10.5C1.75 15.33 5.67 19.25 10.5 19.25C15.33 19.25 19.25 15.33 19.25 10.5C19.25 5.67 15.33 1.75 10.5 1.75ZM10.5 17.5C6.6325 17.5 3.5 14.3675 3.5 10.5C3.5 6.6325 6.6325 3.5 10.5 3.5C14.3675 3.5 17.5 6.6325 17.5 10.5C17.5 14.3675 14.3675 17.5 10.5 17.5Z"
          fill="#43A047"
        />
      </svg>
    </div>
  );

  const inactiveMark = (
    <div className="wizard-stepper__mark">
      <svg
        width="7"
        height="7"
        viewBox="0 0 7 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="3.5"
          cy="3.5"
          r="2.5"
          fill="#CFD8DC"
          stroke="#CFD8DC"
          strokeWidth="2"
        />
      </svg>
    </div>
  );

  return (
    <>
      <div className="card card--thin card--borderless">
        <div className="card-body card-body">
          <ul className="wizard-stepper list-unstyled">
            {[
              "Connect to Crowdin",
              "Crowdin project",
              "Setup integration",
              "Translate",
            ].map((text, idx) => {
              return (
                <li
                  key={idx}
                  className={`wizard-stepper__step${
                    idx === 3 ? " wizard-stepper__step--last" : ""
                  }`}
                >
                  {stepperActiveStepIndex > idx
                    ? doneMark
                    : stepperActiveStepIndex === idx
                    ? activeMark
                    : inactiveMark}
                  <div
                    className={`wizard-stepper__text${
                      stepperActiveStepIndex >= idx
                        ? " wizard-stepper__text--active"
                        : ""
                    }${
                      stepperActiveStepIndex > idx
                        ? " wizard-stepper__text--with-border"
                        : ""
                    }`}
                  >
                    {text}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="mt-5 mb-5 wizard-testimonial">
        <img
          src="/github_logo.png"
          alt="Github logo"
          title="Github logo"
          className="wizard-testimonial__logo mb-2"
        />
        <p className="fw-500 lh-base">
          "When we evaluated localization options for the Electron project,
          Crowdin stood apart from the competition. It’s free for open source
          projects, it integrates well with GitHub, and has moderation controls
          for managing large translator communities. Crowdin’s support and
          engineering staff are also friendly and helpful."
        </p>
        <div className="d-flex align-items-center">
          <img
            src="/zeke.jpg"
            alt="Zeke Sikelianos"
            title="Zeke Sikelianos"
            className="rounded-circle"
            width="32"
          />
          <div className="d-flex flex-column ms-2">
            <span className="fw-500 text-small">Zeke Sikelianos</span>
            <span className="text-extra-small text-muted fw-500">
              Senior Engineer, GitHub
            </span>
          </div>
        </div>
      </div>
      <div>
        <h6 className="text-muted mb-2 text-uppercase">Useful links</h6>
        <ul className="list-unstyled">
          <li className="mb-2">
            <a
              rel="noreferrer"
              target="_blank"
              href="https://store.crowdin.com"
            >
              Crowdin Store
            </a>
          </li>
          <li>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://support.crowdin.com/"
            >
              Crowdin Help Center
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}

export default LeftColumn;
