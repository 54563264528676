class Storage {
  tokenKey = "crowdinWizardToken";

  projectKey = "crowdinWizardProjectId";

  getToken() {
    return localStorage.getItem(this.tokenKey);
  }

  setToken(token) {
    localStorage.setItem(this.tokenKey, token);
  }

  removeToken() {
    localStorage.removeItem(this.tokenKey);
  }

  getProjectId() {
    return localStorage.getItem(this.projectKey);
  }

  setProjectId(projectId) {
    localStorage.setItem(this.projectKey, projectId);
  }

  removeProjectId() {
    localStorage.removeItem(this.projectKey);
  }

  clear() {
    this.removeToken();
    this.removeProjectId();
  }
}

const storage = new Storage();
export default storage;
