import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { find } from "lodash";

import storage from "common/storage";
import parseJwt from "common/parseJwt";
import fetchApi from "common/fetchApi";
import withToasts from "hoc/withToasts";
import fetchAppStoreData from "common/fetchAppStoreData";

import Navbar from "components/Navbar";
import AuthStep from "components/AuthStep";
import LeftColumn from "components/LeftColumn";
import ProjectStep from "components/ProjectStep";
import NoAccessPage from "components/NoAccessPage";
import NotFoundPage from "components/NotFoundPage";
import TranslateStep from "components/TranslateStep";
import CrowdinAppStep from "components/CrowdinAppStep";

const INTEGRATIONS_MODULE_TYPE = "project-integrations";

function getStepperActiveStepIndex(token, projectId, appStoreData, filesCount) {
  if (!token) {
    return 0;
  }

  if (!projectId) {
    return 1;
  }

  if (filesCount === 0) {
    return 2;
  }

  return 3;
}

function getInitialToken() {
  const savedToken = storage.getToken();
  if (savedToken) {
    const parsedData = parseJwt(savedToken);
    if (parsedData.exp * 1000 > new Date().getTime()) {
      return savedToken;
    } else {
      storage.clear();
      return null;
    }
  }
  return null;
}

function WizardPage(props) {
  const [token, setToken] = useState(getInitialToken());
  const [isAppFetching, setIsAppFetching] = useState(true);
  const [isUserFetching, setIsUserFetching] = useState(
    () => !!getInitialToken()
  );
  const [languages, setLanguages] = useState([]);
  const [projectId, setProjectId] = useState(null);
  const [projectIdentifier, setProjectIdentifier] = useState(null);
  const [user, setUser] = useState(null);
  const { app: appId } = useParams();
  const [manifestData, setManifestData] = useState({});
  const [appStoreData, setAppStoreData] = useState({});
  const [installedAppData, setInstalledAppData] = useState({});
  const [moduleData, setModuleData] = useState({});
  const [filesCount, setFilesCount] = useState(0);
  const [noAppFound, setNoAppFound] = useState(false);
  const [appInstallError, setAppInstallError] = useState(false);
  const [workflowId, setWorkflowId] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [templatesFetched, setTemplatesFetched] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const { domain = "" } = token ? parseJwt(token) : {};

  function getWorkflowTemplates() {
    return fetchApi("workflow-templates", token, { groupId: 0 })
      .then((templates) =>
        templates ? templates.map((template) => template.data) : []
      )
      .then((templates) => {
        setTemplates(templates);
        return templates;
      });
  }

  function getAppStoreData(appId) {
    fetchAppStoreData(appId)
      .then((r) => {
        if (r && r.data && r.data.length > 0) {
          const storeData = r.data[0];
          if (process.env.REACT_APP_DEBUG_APP_MANIFEST_URL) {
            storeData.manifest = process.env.REACT_APP_DEBUG_APP_MANIFEST_URL;
          }
          setAppStoreData(storeData);

          getManifestData(appId);
        } else {
          setNoAppFound(true);
        }
      })
      .catch(() => setNoAppFound(true));
  }

  function getManifestData(appId) {
    fetchApi(
      "api/manifest",
      token,
      {
        identifier: appId,
      },
      "GET",
      true
    ).then((r) => {
      if (
        r &&
        r.identifier &&
        r.modules &&
        r.modules[INTEGRATIONS_MODULE_TYPE]
      ) {
        setIsAppFetching(false);
        setManifestData(r);
      } else {
        setNoAppFound(true);
      }
    });
  }

  function logout() {
    storage.clear();
    setToken(null);
    setProjectId(null);
    setFilesCount(0);
    setIsAdmin(false);
    setUser(null);
    setAppInstallError(false);
  }

  useEffect(() => {
    getAppStoreData(appId);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      !token ||
      !appStoreData.manifest ||
      !manifestData.identifier ||
      !user ||
      !isAdmin
    ) {
      return;
    }

    if (domain) {
      getWorkflowTemplates().then((templates) => {
        const defaultWorkflow = find(
          templates,
          ({ title }) => title === "In-house Translators"
        );
        setTemplatesFetched(true);
        if (defaultWorkflow) {
          setWorkflowId(defaultWorkflow.id);
        } else if (templates.length) {
          setWorkflowId(templates[0].id);
        }
      });
    }

    fetchApi("api/applications", token, { limit: 500 }, "GET", true)
      .then((installedApps) => {
        const currentApp = find(
          installedApps.data,
          (app) =>
            app.data.identifier.toLowerCase() ===
            manifestData.identifier.toLowerCase()
        );
        if (!currentApp) {
          // install app
          return fetchApi(
            "applications/installations",
            token,
            {
              url: appStoreData.manifest,
              permissions: {
                project: {
                  value: "own",
                  ids: [],
                },
                user: {
                  value: "owner",
                  ids: [],
                },
              },
            },
            "POST"
          ).then((response) => {
            if (window.dataLayer && response) {
              window.dataLayer.push({
                event: 'Marketplace',
                application: response.identifier,
                label: 'Installed',
                userId: user.id,
                domain,
              });
            }

            return response;
          });
        }
        if (process.env.REACT_APP_DEBUG_APP_MANIFEST_URL) {
          currentApp.data.manifestUrl =
            process.env.REACT_APP_DEBUG_APP_MANIFEST_URL;
          currentApp.data.baseUrl = process.env.REACT_APP_DEBUG_APP_BASE_URL;
          currentApp.data.modules = currentApp.data.modules.map((module) => {
            module.iframe.location.url =
              process.env.REACT_APP_DEBUG_APP_MODULE_URL;
            return module;
          });
        }
        return currentApp.data;
      })
      .then((data) => {
        const module = data
          ? find(
              data.modules,
              (module) => module.type === INTEGRATIONS_MODULE_TYPE
            )
          : null;
        if (module) {
          setInstalledAppData(data);
          setModuleData(module);
        } else {
          setAppInstallError(true);
        }
      });
    // eslint-disable-next-line
  }, [token, appStoreData, manifestData, user, isAdmin]);

  useEffect(() => {
    if (!token) {
      return;
    }

    fetchApi("user", token).then((user) => {
      setIsUserFetching(false);

      if (user) {
        setUser(user);
        setIsAdmin(user.isAdmin || typeof user.isAdmin === "undefined");
      } else {
        logout();
      }
    });

    fetchApi("languages", token, {
      limit: 500,
    }).then(
      (languages) =>
        languages && setLanguages(languages.map((language) => language.data))
    );
    // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    if (projectId) {
      const intervalId = setInterval(() => {
        fetchApi(`projects/${projectId}/files`, token).then((r) => {
          if (r && r.length) {
            setFilesCount(r.length);
          }
        });
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [projectId, token]);

  if ((!noAppFound && isAppFetching) || isUserFetching) {
    return (
      <div className="wizard-preloader">
        <Navbar />
      </div>
    );
  }

  if (noAppFound) {
    return <NotFoundPage logout={logout} />;
  }

  if (user && !isAdmin) {
    return (
      <NoAccessPage
        logout={() => {
          logout();
        }}
      />
    );
  }

  return (
    <div className="wizard-wrapper">
      <Navbar />
      <div className="container-lg bd-gutter mt-3">
        <h1 className="fw-bold wizard-title">
          Agile localization for {appStoreData.name}
        </h1>
        <p className="wizard-subtitle">
          Quickly setup agile localization for {appStoreData.name} in under 2 minutes
        </p>
        <div className="row">
          <div className="col-3 d-none d-lg-block">
            <LeftColumn
              stepperActiveStepIndex={getStepperActiveStepIndex(
                token,
                projectId,
                appStoreData,
                filesCount
              )}
            />
          </div>
          <div className="col-12 col-lg-9">
            <AuthStep
              appStoreData={appStoreData}
              user={user}
              token={token}
              isActive={!token}
              onError={() => {}}
              onTokenReceived={(token) => {
                if (token) {
                  setToken(token);
                } else {
                  logout();
                }
              }}
            />
            <ProjectStep
              user={user}
              token={token}
              domain={domain}
              projectId={projectId}
              languages={languages}
              appStoreData={appStoreData}
              templatesFetched={templatesFetched}
              workflowId={workflowId}
              setWorkflowId={setWorkflowId}
              isDisabled={!token}
              isActive={token && !projectId}
              templates={templates}
              onProjectSelected={(projectId, projectIdentifier = null) => {
                setProjectIdentifier(projectIdentifier);
                setProjectId(projectId);
                if (!projectId) {
                  setFilesCount(0);
                }
              }}
              getWorkflowTemplates={getWorkflowTemplates}
            />
            {!appInstallError && (
              <CrowdinAppStep
                token={token}
                projectId={projectId}
                appModule={moduleData}
                appContext={{
                  project_id: (projectId || "").toString(),
                }}
                requestParams={{
                  projectId: (projectId || "").toString(),
                }}
                appStoreData={appStoreData}
                installedAppData={installedAppData}
                isActive={appStoreData && projectId && filesCount === 0}
                isDisabled={!appStoreData || !projectId}
              />
            )}
            {appInstallError && (
              <div className="card card--active mt-4">
                <div className="card-body">
                  <h2 className="fw-bold">Setup integration</h2>
                  <div className="alert alert-warning mt-2">
                    Integration is not responding. Please try again later or{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://crowdin.com/contacts"
                    >
                      contact our support team
                    </a>
                    .
                  </div>
                </div>
              </div>
            )}
            <TranslateStep
              domain={domain}
              projectId={projectId}
              projectIdentifier={projectIdentifier}
              isActive={appStoreData && projectId && filesCount > 0}
              isDisabled={!appStoreData || !projectId || filesCount === 0}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withToasts(WizardPage);
