function Card(props) {
  const { title, children, isActive, isDisabled, className } = props;

  return (
    <div
      className={`card ${className}${isActive ? " card--active" : ""}${
        isDisabled ? " card--disabled" : ""
      }`}
    >
      <div className="card-body">
        {title && <h2 className="fw-bold mb-3">{title}</h2>}
        {children}
      </div>
    </div>
  );
}

export default Card;
