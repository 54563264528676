import ToastsContext from "contexts/ToastsContext";

const withToasts = (Component) => {
  return (props) => {
    return (
      <ToastsContext.Consumer>
        {({ toasts, showToast }) => {
          return <Component {...props} toasts={toasts} showToast={showToast} />;
        }}
      </ToastsContext.Consumer>
    );
  };
};

export default withToasts;
