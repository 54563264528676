import ToastsContext from "contexts/ToastsContext";

function Toasts() {
  return (
    <ToastsContext.Consumer>
      {({ toasts }) => (
        <div
          className="toast-container p-3 bottom-0 start-50 translate-middle-x"
          id="toastPlacement"
        >
          {toasts.map((text, idx) => (
            <div
              key={idx}
              className="toast fade show align-items-center"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div className="d-flex">
                <div className="toast-body">{text}</div>
              </div>
            </div>
          ))}
        </div>
      )}
    </ToastsContext.Consumer>
  );
}

export default Toasts;
