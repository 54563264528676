const CrowdinLoginButtons = (props) => {
  const { onLoginClick, onSignUpClick } = props;

  return (
    <div className='d-flex'>
      <button onClick={onSignUpClick} className="btn btn-primary me-2">
        Sign up
      </button>
      <button onClick={onLoginClick} className="btn btn-secondary">
        Login
      </button>
    </div>
  );
};

export default CrowdinLoginButtons;
