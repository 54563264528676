import CrowdinLogin from "./CrowdinLogin";

function LoginButton({ authCallback = () => {} }) {
  return (
    <CrowdinLogin
      authCallback={authCallback}
      clientId={process.env.REACT_APP_CROWDIN_CLIENT_ID}
      clientSecret={process.env.REACT_APP_CROWDIN_CLIENT_SECRET}
      redirectUri={`${process.env.REACT_APP_BASE_URL}/auth/callback`}
      scope={"*"}
    />
  );
}

export default LoginButton;
